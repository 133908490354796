import { FC } from 'react';
import Layout from '@/components/layout/Layout';
import TopSearchWidgets from '@/components/ui/top-search-widgets/TopSearchWidgets';
import BookCardsList from '@/components/ui/book-cards-list/BookCardsList';
import SectionSubtitle from '@/components/ui/section-sub-title/SectionSubTitle';
import { IBookPage } from '@/interfaces/booksPage.interface';
import { IPopularityBooks } from '@/interfaces/popularityBooks.interface';

interface IHomePage {
  newBooks: IBookPage;
  popularBooks: IBookPage;
  topBooks: IPopularityBooks;
}

const Home: FC<IHomePage> = ({ newBooks, popularBooks, topBooks }) => {
  //seo block
  const SEO_TITLE = 'fantasy-worlds.ru - Миры Фэнтези';
  const SEO_DESCRIPTION = '«Fantasy-Worlds» - огромная библиотека электронных книг и аудиокниг, которые доступны бесплатно онлайн.';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref="/">
      <TopSearchWidgets popularBooks={popularBooks} topBooks={topBooks} />
      <SectionSubtitle name="Новые книги" />
      {!!newBooks.data.length ? <BookCardsList books={newBooks.data} /> : 'Книги не найдены'}
    </Layout>
  );
};

export default Home;
