import Home from '@/components/screens/home/Home';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, REVALIDATE_TIME } from '@/helpers/variables';
import { IBookPage } from '@/interfaces/booksPage.interface';
import { IPopularityBooks } from '@/interfaces/popularityBooks.interface';

interface IHomePage {
  popularBooks: IBookPage;
  newBooks: IBookPage;
  topBooks: IPopularityBooks;
}

const HomePage: NextPage<IHomePage> = ({ popularBooks, newBooks, topBooks }) => {
  return <Home popularBooks={popularBooks} newBooks={newBooks} topBooks={topBooks} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const [popularRes, newRes, topBooksRes] = await Promise.all([
    fetch(`${API_URL}satellite/books/popular?page=1&page_size=5`),
    fetch(`${API_URL}satellite/books/news?page=1&page_size=10`),
    fetch(`${API_URL}satellite/books/popularity?page=1&page_size=1`),
  ]);
  const [popularBooks, newBooks, topBooks] = await Promise.all([popularRes.json(), newRes.json(), topBooksRes.json()]);

  return { props: { popularBooks, newBooks, topBooks }, revalidate: REVALIDATE_TIME };
};

export default HomePage;
